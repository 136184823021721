@font-face {
    font-family: "Scada";
    src: url(../assets/fonts/scadabold.woff) format("woff"), url(../assets/fonts/scadabold.woff2) format("woff2"), url(../assets/fonts/scadabold.ttf) format("ttf");
    font-weight: 900;
}

@font-face {
    font-family: "Scada";
    src: url(../assets/fonts/scada.woff) format("woff"), url(../assets/fonts/scada.woff2) format("woff2"), url(../assets/fonts/scada.ttf) format("ttf");
    font-weight: 100;
}

@font-face {
    font-family: "Scada";
    src: url(../assets/fonts/scada.woff) format("woff"), url(../assets/fonts/scada.woff2) format("woff2"), url(../assets/fonts/scada.ttf) format("ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Scandia";
    src: url(../assets/fonts/ScandiaWebMedium.woff) format("woff"), url(../assets/fonts/ScandiaWebMedium.woff2) format("woff2"), url(../assets/fonts/ScandiaWebMedium.eot) format("eot");
    font-weight: 700;
}

a {
    text-decoration: none;
}

.MuiPaper-rounded {
    border-radius: 25px !important;
}
.MuiPaper-rounded .MuiList-padding {
    padding: 1px;
}

/* ::-webkit-scrollbar {
    width: 15px;
    height: 7px;
    background: none;
}
@media (max-width: 900px) { 
    ::-webkit-scrollbar {
        width: 7px;
    }
}

::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 9em;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #a6a6a6;
} */

/* * {
    user-select: none;
} */

